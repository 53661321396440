.popover{
    border: 0.125rem solid $secondary;
    min-width: 13.125rem;
    box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
    border-radius:$radius;
	@at-root [data-theme-version="dark"] & {
        background-color: $d-bg;
    }
    &-header{
        background: $secondary;
        color: $white;
        font-weight: 300;

        &::before{
            border-bottom: 0 !important;
        }
    }

    &-body{
        font-size: 0.75rem;
    }
	.popover-header{
        border-radius:3.5px 3.5px 0px 0px ;
		@at-root [data-theme-version="dark"] & {
			border-color: $dark-card;
		}
    }
	@include respond ('phone-land'){
		z-index:1;
	}
}

.bootstrap-popover-wrapper{
    .bootstrap-popover:not(:last-child){
        margin-right: 0.5rem;
    }

    .bootstrap-popover{
        margin-bottom: .5rem;

        button:hover,button:focus{
            background: var(--primary);
            color: $white;
            box-shadow: none;
        }
    }
}

// .tooltip {
//     font-size: 0.9375rem;
// }


.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 0rem;
    border-top-color: $secondary;
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 0rem;
    border-left-color: $secondary;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 0rem;
    border-right-color: $secondary;
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 0rem;
    border-bottom-color: $secondary;
}

.note-popover{
    display: none;
}