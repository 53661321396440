.table-responsive-lg .custom {
  background-color: #f8f8ff !important;
  height: 34px !important;
}

.table-responsive-lg .row-height {
  height: 34px !important;
}
.table-responsive-lg .row-height .column-width {
  min-width: 65px !important;
  max-width: 65px !important;
}
.table-responsive-lg .row-height .date-column-width {
  min-width: 120px !important;
}
.table-responsive-lg .row-height .sn-font-weight {
  font-weight: bold;
}

.table-responsive-lg .row-height .active-col-width {
  min-width: 80px !important;
}
.table-responsive-lg .toggle-button-color {
color: aqua !important;
}
.table-responsive-lg .header-color {
  color: black !important;
}

.custom-table-header{
  color: #3c4b53 !important;
  font-weight: 605 !important;
 
}