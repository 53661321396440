@media (min-width: 62rem) {
    [data-container="wide-boxed"] {
		--right-space: 0;	
		//background-color:var(--primary);
        #main-wrapper {
            max-width: 92.5rem;
            margin: 0 auto;
			box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
				
            @at-root [direction="rtl"]#{&} {
                text-align: right;
            }
			overflow: hidden;
        }
		.wallet-open .header .header-content{
			padding-right:1.875rem;
		}
		.wallet-bar{
			display:none;
		}
		.nav-link{
			&.menu-wallet{
				display:none;
			}	
		}
        .footer{
            &.footer-outer{
                width: calc(100% - 15.725rem);
                margin-right: 1.875rem;
                //margin-left: auto;
            
            }
        }
    }
}

    
    
@include custommq($min: 84.375rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] {
        .header {
            width: 92.5rem;
        }
    }
}

@include custommq($min: 75rem, $max: 100rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] {
        .header {
            width: 92.5rem;
        }
		
    }
}
[data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] {
	.footer.out-footer.style-1{
			width: calc(100% - 23.525rem);
			margin-right: 20.775rem;

	}	
	.footer.footer-outer{
		width: calc(100% - 4.725rem);
		margin-right: 1.875rem;
	}
	.footer.out-footer.style-2{
		width:calc(100% - 4.325rem);
	}
	
	.header{
		.header-content{
			padding-right:19.875rem;
		}
	}
}
[data-layout="vertical"][data-sidebar-style="icon-hover"][data-container="wide-boxed"][data-header-position="fixed"] {
	.footer.out-footer.style-1{
		width: calc(100% - 28.725rem);
		margin-right: 20.875rem;

	}	
	.footer.footer-outer{
		width: calc(100% - 9.725rem);
		margin-right: 1.875rem;

	}
	.footer.out-footer.style-2{
		width: calc(100% - 9.725rem);
		margin-right: 1.875rem;
	}
	
	
}
[data-layout="vertical"][data-sidebar-style="mini"][data-container="wide-boxed"][data-header-position="fixed"] {
	.footer.out-footer.style-1{
			    width: calc(100% - 28.525rem);
		margin-right: 19.775rem;

	}	
	.footer.footer-outer{
		width: calc(100% - 10.725rem);
		margin-right: 1.875rem;
	}
	.footer.out-footer.style-2{
		width:calc(100% - 11.125rem);
	}
}
[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] {
    .header {
        width: 92.5rem;
		
		.header-content{
			    padding-left: 3rem!important;
				width: calc(100% - 10.1rem);
		}
    }
	
	
   .footer.out-footer.style-2{
		width:calc(100% - 15.625rem);
   }
   .footer.out-footer.style-1{
		width:calc(100% - 35.225rem);
		margin-right:20.975rem;
   }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] {
    .dlabnav {
        max-width: 92.5rem;
    }
} //ok

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"],
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] {
    .header {
        width: 92.5rem;
    }
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .header {
        width: 92.5rem;
    }
}
[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="full"] {
    .header {
        width: 92.5rem;
		.header-content{
			padding-right:19.875rem;
		}
    }
	
	
	.footer{
		&.footer-outer{
			width: calc(100% - 21.725rem);
			margin-right: 1.875rem;
		}
	}
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="compact"] {
    .wallet-open.show.active .dlabnav-scroll{
		padding-right:0px;
	}
}
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="icon-hover"] {
    .footer.footer-outer{
		width:calc(100% - 5.025rem);
		margin-left:1.775rem;
	}
	 .wallet-open.show.active .dlabnav-scroll{
		padding-right:0px;
	}
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="modern"] {
    .wallet-open.show.active .dlabnav-scroll{
		padding-right:0px;
	}
	.header{
		.header-content{
			padding-right:19.875rem;
		}
	}
	.footer{
		&.footer-outer{
			width: calc(100% - 4.225rem);
			margin-left: 2.775rem !important;
		}
		&.style-1{
			width: calc(100% - 24.225rem);
			margin-left: 2.775rem !important;
		}
	}
}


[data-container="wide-boxed"] {
    .metismenu.fixed {
        left: auto;
        max-width: 92.5rem;
    }

    .page-titles {
        margin-bottom: 3rem;
        padding: 0.9375rem 0.9375rem;
    }

    .content-body .container-fluid {
        padding: 2.5rem;
    }
}

[data-container="wide-boxed"][data-layout="vertical"] {
    .page-titles {
        margin-left: 0;
        margin-right: 0;
    }
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] {
    .nav-header {
        position: absolute;
    }

    .menu-toggle {
        .dlabnav {
            position: absolute;
        }
    }
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] {
    .dlabnav.fixed {
        left: auto;
        max-width: 92.5rem;
    }
}
[data-container="wide-boxed"][data-layout="horizontal"][data-header-position="fixed"]{
	&[data-sidebar-style="icon-hover"],
	&[data-sidebar-style="full"]{	
		.content-body{
			padding-top: 14rem;
		}
	}
	&[data-sidebar-style="compact"],
	&[data-sidebar-style="modern"]{
		.content-body{
			padding-top: 12rem;
		}
	}
}