.card .row .custom-left-box {
  /* border-right: 1px solid grey !important; */
}
.padding-left-box > .container-fluid {
  padding-left: 0px !important;
}

.col-xl-12 .card .card-body .question-bank-styling span p {
  color: black !important;
  margin: 5px;
}

.col-xl-12 .card .card-body .question-bank-styling .text-white p {
  color: white !important;
}

.col-xl-12 .card .card-body .question-bank-styling strong {
 padding: .4rem;
 padding-top: .5rem;
 padding-left: .1rem;
 padding-right: .1rem;
}
