@media (min-width: 62rem) {
    [data-container="boxed"] {
		 --right-space: 0;	
		 //background-color:var(--primary);
        #main-wrapper {
            max-width: 74.9375rem;
            margin: 0 auto;
			box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
				
            @at-root [direction="rtl"]#{&} {
                text-align: right;
            }
			overflow: hidden;
        }
		.invoice-num {
			font-size: 1.375rem;
		}
		/* .wallet-open .content-body{
			padding-right: 0;
		} */
	   
		.wallet-open .header .header-content{
			padding-right:1.875rem;
		}
		.wallet-bar{
			display:none;
		}
		.nav-link{
			&.menu-wallet{
				display:none;
			}	
		}
		.header-left .dashboard_bar{

			display: none;
		}
		.header .header-content{

			//width:calc(100% - 17.9rem);
		}
		.footer.style-2{
			width:calc(100% - 3.625rem);

		}
		.footer{

            &.footer-outer{

                width:calc(100% - 3.725rem);
                margin-right:1.875rem;
            }
        }
    }
}
    
@include custommq($min: 84.375rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 74.9375rem;
        }
    }
}

@include custommq($min: 75rem, $max: 84.3125rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 74.9375rem;
        }
    }
}


[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] {
    .dlabnav {
        max-width: 74.9375rem;
    }
	.content-body{
		padding-top: 14rem!important;
	}
} //ok

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"],
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] {
    .header {
        width: 74.9375rem;
    }
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .header {
        width: 74.9375rem;
    }
}
[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .footer.footer-outer{
		width:calc(100% - 10.325rem);
	}
	.footer.out-footer.style-2{
		width:calc(100% - 9.625rem);
	}
	.footer.out-footer.style-1{
		width:calc(100% - 28.125rem);
	}
	
}
[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="compact"] {
    .footer.footer-outer{
		width:calc(100% - 15.225rem);
	}
	.footer.out-footer.style-2{
		width:calc(100% - 15.625rem);
	}
	.footer.out-footer.style-1{
		width:calc(100% - 33.225rem);
	}
	.header{
		.header-content{
			padding-right:11.275rem;
		}
	}
	
}
[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="overlay"]{
    .footer.footer-outer{
		width:calc(100% - 4.225rem);
	}
	.footer.out-footer.style-2{
		width:calc(100% - 15.625rem);
	}
	.footer.out-footer.style-1{
		width:calc(100% - 22.225rem);
	}
	.header{
		.header-content{
			padding-right:312px;
		}
	}
	
}
[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="icon-hover"]{
    .footer.footer-outer{
		width:calc(100% - 9.725rem);
	}
	.footer.out-footer.style-2{
		width:calc(100% - 15.625rem);
	}
	.footer.out-footer.style-1{
		width:calc(100% - 22.225rem);
	}
	
	
}
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="full"] {
   .wallet-open.show.active.dlabnav-scroll{
		 padding-right:0;
	}
}
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="compact"] {
	.wallet-open.show.active .dlabnav-scroll{
		 padding-right:0;
	}
  
}
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="modern"] {
	.footer.style-2{
		width: calc(100% - 3.625rem);
		margin-left: auto;
	}
	.footer.footer-outer{
		width:calc(100% - 3.225rem);
	}
	.wallet-open.show.active.dlabnav-scroll{
		 padding-right:0;
	}
  
}


[data-container="boxed"] {
    .metismenu.fixed {
        left: auto;
        max-width: 74.9375rem;
    }

    .page-titles {
        margin-bottom: 3rem;
        padding: 0.9375rem 0.9375rem;
    }

    .content-body .container-fluid {
        padding:1.875rem;
    }
}

[data-container="boxed"][data-layout="vertical"] {
    .page-titles {
        margin-left: 0;
        margin-right: 0;
    }
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] {
    .nav-header {
        position: absolute;
    }

    .menu-toggle {
        .dlabnav {
            position: absolute;
        }
    }
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] {
    .dlabnav.fixed {
        left: auto;
        max-width: 74.9375rem;
    }
	
	.container-fluid{
		padding-bottom: 0;
	}
	
}
[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"]{
	/* &[data-sidebar-style="full"],
	&[data-sidebar-style="icon-hover"]{
		.content-body{
			padding-top: 9rem;
		}
	} */
	 &[data-sidebar-style="compact"]{
		.content-body{
			padding-top: 18rem!important;
		}
	} 
	/* &[data-sidebar-style="modern"]{
		.content-body{
			padding-top: 6.5rem;
		}
	} */
	&[data-sidebar-style="mini"]{
		.content-body{
			padding-top: 10rem!important;
		}
	}
}
[data-container="boxed"],
[data-container="wide-boxed"],
[data-sidebar-position="static"][data-header-position="static"][data-layout="horizontal"]{
	.nav-header{
		height:5.5rem;
	}
}
[data-container="boxed"][data-sidebar-position="static"][data-header-position="fixed"][data-layout="horizontal"]{
	.nav-header{
		height:5rem;
	}
	&[data-sidebar-style="mini"]{
		.content-body{
			padding-top: 10rem!important;
		}
	}
	&[data-sidebar-style="modern"]{
		.content-body{
			padding-top: 6.5rem;
		}
	}
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"]{
	/* &[data-sidebar-style="full"],
	&[data-sidebar-style="icon-hover"]{
		.content-body{
			padding-top: 9rem;
		}
	} */
	/* &[data-sidebar-style="compact"]{
		.content-body{
			padding-top: 7.5rem;
		}
	} */
	/* &[data-sidebar-style="modern"]{
		.content-body{
			padding-top: 6.5rem;
		}
	} */

	.footer{

		&.footer-outer{

				width:calc(100% - 4.925rem);
				margin-left:2.6rem;
		}
	}
}
[data-container="wide-boxed"][data-sidebar-position="static"][data-layout="horizontal"]{
	
	&[data-sidebar-style="compact"]{
		.header .header-content{
			margin-left: 13.2rem;
		}
	}
}
[data-container="wide-boxed"],
[data-container="boxed"],
[data-container="wide"],
[data-sidebar-position="static"][data-layout="horizontal"][data-header-position="static"]{
	.nav-header{
		height:5rem;
		z-index: 8;
	}
}
	
[data-container="wide"][data-sidebar-position="fixed"][data-layout="horizontal"]{
	&[data-sidebar-style="compact"]{
		.header{
			.header-content{
				margin-left: 12.2rem;
			}
		}
	}
}
[data-container="boxed"]{
	.header-left{
		.search-area{
			display:none;
		}
	}
}