.toast {
    &-success {
        background-color: var(--primary);
    }

    &-info {
        background-color: $info;
    }

    &-warning {
        background-color: $warning;
    }

    &-error {
        background-color: $danger;
    }
}

#toast-container {

    &>div {
        box-shadow: none;
        border-radius: $radius;
        width: auto;
        max-width: 15.625rem;
        opacity: 1;

        @at-root [direction="rtl"] & {
            padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
            background-position: calc(100% - 0.9375rem);
            text-align: right;
        }

        &:hover {
            box-shadow: none;
        }
    }

    .toast-title {
        margin-bottom: 0.3125rem;
        font-weight: 600;
    }

    .toast-message {
        font-size: 0.75rem;
    }

    .toast-close-button {
        opacity: 1;
        font-size: 1.25rem;
        font-weight: normal;
        text-shadow: none;
    }


}
#toast-container.toast-bottom-full-width>div, #toast-container.toast-top-full-width>div{
	width:100%;
	min-width:300px;
	display:block;
	padding:15px 15px 18px 60px;
	box-shadow:0 0 50px rgba(0,0,0,0.15);
}
.toast-progress{
	background-color:$white;
}

[direction="rtl"] {
    .toast-top-right.demo_rtl_class {
        left: 0.75rem;
        right: auto;
    }
}