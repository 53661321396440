
#preloader{
	display: flex;
     align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100%;
     z-index: 999999;
 	//background-color: #e6ebee;
	
	
	.dot {
	  background: var(--primary);
	  border-radius: 50%;
	  display: inline-block;
	  margin-right: 20px;
	  width: 25px;
	  height: 25px;
}

.dot:first-child {
  animation: FirstDot 3s infinite;
}

.dot:nth-child(2) {
  animation: SecondDot 3s infinite;
}

.dot:nth-child(3) {
  animation: ThirdDot 3s infinite;
}

.dot:nth-child(4) {
  animation: FourthDot 3s infinite;
}

.dot:nth-child(5) {
  animation: FifthDot 3s infinite;
}

@keyframes FirstDot {
  0% {
    transform: scale(1) translateX(0);
  }
  25% {
    transform: scale(2.5) translateX(0);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  83% {
    transform: scale(1) translateX(240px);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes SecondDot {
  0% {
    transform: translateX(0px);
  }
  27% {
    transform: translateX(-40px);
  }
  50% {
    transform: translateX(0px);
  }
  81% {
    transform: translateX(180px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes ThirdDot {
  0% {
    transform: translateX(0px);
  }
  29% {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(0px);
  }
  79% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FourthDot {
  0% {
    transform: translateX(0px);
  }
  31% {
    transform: translateX(-160px);
  }
  50% {
    transform: translateX(0px);
  }
  77% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FifthDot {
  0% {
    transform: scale(1) translateX(0);
  }
  33% {
    transform: scale(1) translateX(-220px);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  75% {
    transform: scale(2.5) translateX(0);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

	
}