.header-left {
    height: 100%;
    display: flex;
    align-items: center;
	.search-area{
		width: 25rem;
		flex-direction: row-reverse;
		border: 1px solid $b-color;
		overflow: hidden;
		border-radius:$radius;
		box-sizing: border-box;
   		height: 3rem;
		@include custommq($max:117.5rem){
			width: 22rem;
		}
		@include custommq($max:81.25rem){
			width: 17.5rem;
		}
		@include respond ('tab-land'){
			width:	15.625rem;
		}
		@include custommq ($max:68.75rem){
			display:none;
		}
		.form-control{
			font-size:15px;
			border-right:0;
			border: none;
			padding: 0rem;
			border-radius:0 $radius $radius 0; 
			padding-left: 0.2rem;
			height: 3rem;
			&:focus{
				border-color:white;
				box-shadow: none;
			}
		}
		.input-group-text{
			background:$white !important;
			border: none;
			padding-right: 0;
			height: 3rem;
			border-radius:$radius 0 0 $radius; 
			svg{
				transform: scale(1.2);
				path{
					fill:var(--primary);
				}
				
			}
		}
	}
	
	.breadcrumb{
		margin-bottom:0;
	}
	.agenda{
		a{
			border-radius:1.75rem;
			i{
				margin-right:0.75rem;
				@include respond ('laptop'){
					margin-right:0rem;	
				}
			}
			@include custommq ($max:93.75rem){
				font-size:0;
				border-radius: 0.75rem;
				padding: 0.8125rem 0.9375rem;
				i{
					font-size:1.25rem;
					margin-right:0;
				}
			}
		}
		@include respond('phone') {
			display:none;	
		}
	}
	.dashboard_bar {
		font-size: 1.563rem;
		font-weight: 600;
		color:$headings-color;
		
		@include respond('phone-land') {
			display:none;
		}
		&.sub-bar{
			font-size:1.875rem;
		}
	}
	
}

[data-sidebar-style="compact"] {
    .header-left {
        margin-left: 0;
    }
}

.nav-item.notification_dropdown{
	a.nav-link{
		position: relative;
		/* &::before{
			content: '';
			display: block;
			background-color: $pink;
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			position: absolute;
			top: -2px;
			right: 0;
			
		}
		 */
	}
	.nav-link.menu-wallet{
		&::before{
			content: '';
			display: none;
		}
	}
}

.modal{
	.modal-content{
		input.form-control{
			border-top-right-radius: $radius !important;
			border-bottom-right-radius: $radius !important;
		}
	}
}