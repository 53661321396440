// .modal{
//     border: 0;
//     z-index: 9999999999;
    
//     &-content{
//         border: 0;
//         box-shadow: 0rem 0.125rem 1.3125rem 0.375rem rgba(70, 66, 66, 0.26);
//     }
// }

// // primary modal rule
// #primaryModal{

//     .modal-header{
//         background: $primary;

//         .modal-title,button{
//             color: #fff;
//             opacity: 1;
//         }
//     }
// }
// //danger modal rule
// #dangerModal{

//     .modal-header{
//         background: $danger;

//         .modal-title,button{
//             color: #fff;
//             opacity: 1;
//         }
//     }
// }

// //warning modal rule
// #warningModal{

//     .modal-header{
//         background: $warning;

//         .modal-title,button{
//             color: #a39e06 ;
//             opacity: 1;
//         }
//     }
// }

// //success modal rule
// #successModal{

//     .modal-header{
//         background: $success;

//         .modal-title,button{
//             color: #fff;
//             opacity: 1;
//         }
//     }
// }







// .bootstrap-modal-wrapper{

//     button:not(:last-child){
//         margin-right: 0.4375rem;
//     }

//     button{
//         margin-bottom: .5rem;

//         &:focus{
//             box-shadow: none;
//         }
//     }
// }

.modal-header{
	padding: 1rem 1.875rem;

	.close{
		padding: 0.875rem 1.815rem;
		margin: 0;
		position: absolute;
		right: 0;
		float: none;
		top: 0;
		font-size: 1.875rem;
		font-weight: 100;
	}
}
.modal-body{
	padding: 1.875rem;
}
.modal-footer{
	padding: 1rem 1.875rem;
}
.modal-content{
	border-radius:$radius;
}
.modal-backdrop{
    z-index: 10!important;
}

.Modal-box {
	background-color: $white;
	height: 300px;
	border-radius: 8px;
	overflow: hidden;
	@include respond('phone'){
		margin-bottom: 0.875rem;
	}
	.box1 {
		display: flex;
		justify-content: space-between;
		padding: 20px 25px;
		border-bottom: 1px solid #3736ae30;
		h3 {
			color: black;
		}
		i {
			font-size: 20px;
		}
	}
	.box2 {
		padding: 10px 25px;
		height: 150px;
	}
	.box3 {
		padding: 20px 25px;
		text-align: end;
		background-color: #f4f4f5;
		border-top: 1px solid #3736ae30;

		.bt-1 {
			color: #f34e5f;
			background-color: #e9c7cd;
			padding: 5px 15px;
			border: 1px solid #e9c7cd;
			margin-right: 5px;
			border-radius: 6px;
		}
		.bt-2 {
			color: $white;
			background-color: #3736ae;
			border: 1px solid #3736ae;
			padding: 5px 15px;
			border-radius: 6px;
		}
		
	}

}