.p-1 .price-color {
  color: green !important;
}
.custom-select .react-select__menu {
  background-color: #ffffff !important; /* Set background to white */
  z-index: 9999 !important; /* Ensure the menu appears above other content */
}
.custom-dropdown {
  background-color: #ffffff !important; /* Set background to white */
  z-index: 9999 !important;
}
.column-width .form-switch {
    padding: auto !important; 
}
.col-md-12 .full-width-date{
  width: 100% !important;
}
