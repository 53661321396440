.contact_list{
	@include transitionSlow;
	.dropdown{
		position: absolute;
		top: 20px;
		right: 20px;			
	}
	
	&:hover{
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}
	.btn-check:focus + .btn, .btn:focus {
		outline: 0;
		
	}
	
}


/* Teacher Detail */

.user-bg{
	height:140px;
    background-color: var(--primary);
	border-radius:$radius $radius 0 0;
	position:relative;
	
	.user-svg,
	.user-svg-1{
		position:absolute;
		bottom:0;
		right:40px;
		z-index:1;
		
		@include respond ('phone'){
			right:27px;
		}

		@include respond('tab-port'){
			svg{
				width:9.375rem;
				height:6.25rem;
				
				@include respond ('phone'){
					width:7.738rem;
					height:4.813rem;
				}
				
			}
			

		}
	}

	.user-svg-1{
		right:144px;
		z-index:0;
		
		@include respond ('phone'){
			right:68px;
		}
	}
	
}
.user{
	margin-top: -100px;
	@include respond ('phone'){
		margin-top:-72px;
	}
	.user-media{
		margin-bottom: 20px;
		
		@include respond ('phone'){
			margin-bottom:10px;
		}
		.avatar{
			border:8px solid $white;
			
			@include respond ('phone'){
				border-width:5px;
				
			}
		}
	}
}

.user-content{
	.user-img{
		margin-bottom:1.5rem;
	}
	.user-details{
		//margin-bottom:1.875rem;
	}
	.sharp{
		@include respond ('tab-land'){
			width:2.4rem;
			height:2.4rem;
		}
	}
}

.teacher-deatails{
	//margin-top:2.188rem;
	p{
		width:60%;
		
		@include respond ('laptop'){
			width:100%;
		}
	}
	.dots{
		position:relative;
		padding-left:0.938rem;
		margin-bottom:1.563rem;
			
		&:after{
			content:"";
			position:absolute;
			background-color:var(--primary);
			width:5px;
			height:5px;
			border-radius:100%;
			top:0.5rem;
			left:0;
		}
	}
}
.contact-icon{
	margin-bottom:30px;
}
.contact_list{
	.card-body{
		@include respond ('tab-land'){
			padding:20px;
		}
	}
}


.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: -5px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
					content: "\f303";
                    font-family: 'Font Awesome 5 Free';
					font-weight: 900;
                    color: #757575;
                    position: absolute;
                    top:6px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 130px;
        height: 130px;
        position: relative;
        border-radius: 100%;
        border: 3px solid #F8F8F8;
		overflow: hidden;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}
.project-boxed {
    overflow: hidden;
}